import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';
import { Save, X, Trash, Edit } from 'lucide-react';

const CityCard = ({ city, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localCity, setLocalCity] = useState({
    newName: city.name,
    newDescription: city.description,
    newImageUrl: city.image_url,
    newHighlightUuid: city.highlight_uuid,
  });

  const handleSave = async () => {
    try {
      const { error } = await supabase.from('cities').update({
        name: localCity.newName,
        description: localCity.newDescription,
        image_url: localCity.newImageUrl,
        highlight_uuid: localCity.newHighlightUuid,
      }).eq('id', city.id);
      if (error) throw error;

      onUpdate();
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating city:', error);
      alert('Error updating city');
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer cette ville ?");
    if (confirmDelete) {
      try {
        const { error } = await supabase.from('cities').delete().eq('id', city.id);
        if (error) throw error;

        onUpdate(); // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting city:', error);
        alert('Error deleting city');
      }
    }
  };

  return (
    <div className="dashboard-card">
      {isEditing ? (
        <div className="edit-form">
          <input
            value={localCity.newName}
            onChange={(e) => setLocalCity({ ...localCity, newName: e.target.value })}
            className="edit-input"
            placeholder="Nom de la ville"
          />
          <textarea
            value={localCity.newDescription}
            onChange={(e) => setLocalCity({ ...localCity, newDescription: e.target.value })}
            className="edit-textarea"
            placeholder="Description"
          />
          <input
            value={localCity.newImageUrl}
            onChange={(e) => setLocalCity({ ...localCity, newImageUrl: e.target.value })}
            className="edit-input"
            placeholder="URL de l'image"
          />
          <input
            value={localCity.newHighlightUuid}
            onChange={(e) => setLocalCity({ ...localCity, newHighlightUuid: e.target.value })}
            className="edit-input"
            placeholder="UUID du highlight"
          />
          <div className="edit-actions">
            <button onClick={handleSave} className="save-button">
              <Save size={16} /> Sauvegarder
            </button>
            <button onClick={() => setIsEditing(false)} className="cancel-button">
              <X size={16} /> Annuler
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="card-header">
            <h3 className="card-title">{city.name}</h3>
          </div>
          <div className="action-buttons">
            <button onClick={() => setIsEditing(true)} className="edit-button">
              <Edit size={16} /> Éditer
            </button>
            <button onClick={handleDelete} className="delete-button">
              <Trash size={16} /> Supprimer
            </button>
          </div>
          <div className="card-content">
            <p>{city.description}</p>
            <img src={city.image_url} alt={city.name} className="city-image" />
          </div>
        </>
      )}
    </div>
  );
};

export default CityCard;
