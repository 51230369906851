import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { User, LogOut, Building, Anchor, MapPin } from 'lucide-react'; // Replace Edit3 with MapPin for Place Manager
import { supabase } from '../../supabaseClient'; 
import './AppSidebar.css';

const AppSidebar = ({ userEmail }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  return (
    <div className="app-sidebar">
      <div className="sidebar-header">
        <User size={24} className="user-icon" />
        <span className="username">{userEmail}</span>
      </div>
      
      <nav className="sidebar-nav">
        <ul className="nav-list">
          <li className="nav-item">
            <Link to="/gestion-escales" className="nav-link">
              <Anchor size={20} className="icon" />
              <span>Gestion des Escales</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/gestion-villes" className="nav-link">
              <Building size={20} className="icon" />
              <span>Gestion des Parcours</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/place-manager" className="nav-link"> {/* Link to Place Manager */}
              <MapPin size={20} className="icon" />
              <span>Gestion des lieux</span>
            </Link>
          </li>
        </ul>
      </nav>
      
      <button onClick={handleLogout} className="logout-button">
        <LogOut size={20} className="icon" />
        <span>Se déconnecter</span>
      </button>
    </div>
  );
};

export default AppSidebar;
