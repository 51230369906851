import React, { useState, useEffect } from 'react';
import AppSidebar from '../AppSidebar/AppSidebar';
import CityManager from '../CityManager/CityManager';
import { supabase } from '../../supabaseClient';
import Escales from '../Escales/Escales';
import './Dashboard.css';

const Dashboard = () => {
  const [userEmail, setUserEmail] = useState('');
  const [activeSection, setActiveSection] = useState('cities'); // New state for active section
  const [cities, setCities] = useState([]);
  const [userCities, setUserCities] = useState([]);
  const [escales, setEscales] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUserData = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUserEmail(user.email);
        await checkAdminStatus(user.id);
        await loadData(user.id);
      }
    };
    getUserData();
  }, []);

  const checkAdminStatus = async (userId) => {
    setIsAdmin(userId === '3e0607aa-51f3-4bfc-b276-c9ebe9328275');
  };

  const loadData = async (userId) => {
    setLoading(true);
    try {
      if (activeSection === 'cities') {
        await loadCities(userId);
      } else if (activeSection === 'escales') {
        await loadEscales();
      }
    } finally {
      setLoading(false);
    }
  };

  const loadCities = async (userId) => {
    try {
      if (isAdmin) {
        const { data, error } = await supabase
          .from('cities')
          .select('*')
          .order('name');
        if (error) throw error;
        setCities(data);
      } else {
        const { data, error } = await supabase
          .from('city_managers')
          .select('cities (*)')
          .eq('user_id', userId);
        if (error) throw error;
        setUserCities(data.map(item => item.cities));
      }
    } catch (error) {
      console.error('Error loading cities:', error);
    }
  };

  const loadEscales = async () => {
    try {
      const { data, error } = await supabase
        .from('escales')
        .select('*')
        .order('name');
      if (error) throw error;
      setEscales(data);
    } catch (error) {
      console.error('Error loading escales:', error);
    }
  };

  return (
    <div className="dashboard-container">
  
      <div className="main-content">
        {loading ? (
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <p>Chargement...</p>
          </div>
        ) : activeSection === 'cities' ? (
          <CityManager
            cities={cities}
            userCities={userCities}
            isAdmin={isAdmin}
          />
        ) : (
          <Escales escales={escales} isAdmin={isAdmin} />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
