import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';
import './LoginPage.css';

// Initialize Supabase client (replace with your own URL and key)
const supabase = createClient('https://fwhkmvwumtdtakjntdol.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZ3aGttdnd1bXRkdGFram50ZG9sIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY1MTUwNjUsImV4cCI6MjA0MjA5MTA2NX0.3DrcPDHCPzL2jNblNs5y3jofYUOKlynQqBpuT5EknQw');

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });

      if (error) throw error;

      navigate('/dashboard');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <div className="login-form">
          <div className="logo">
           
          </div>
          <h2 className="form-title">Mon espace administrateur</h2>
          <p className="form-subtitle">Entrez vos identifiants pour accéder aux données de votre ville.</p>
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="email" className="input-label">Email</label>
              <input
                id="email"
                type="email"
                required
                className="input-field"
                placeholder="Entrez votre adresse email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label htmlFor="password" className="input-label">Mot de passe</label>
              <div className="password-input-container">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  required
                  className="input-field"
                  placeholder="Entrez votre mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff /> : <Eye />}
                </button>
              </div>
            </div>
            <div className="login-options">
              <div className="remember-me">
                <input
                  id="remember-me"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label htmlFor="remember-me">Se souvenir de moi</label>
              </div>
              <a href="#">Mot de passe oublié ?</a>
            </div>
            <button type="submit" className="login-button">Se connecter</button>
          </form>
          <p className="register-link">
            Vous n'avez pas de compte ? <a href="#">Inscrivez-vous ici</a>
          </p>
        </div>
      </div>
      
      <div className="decorative-image">
  <div className="text-content-login">
    <div className="text-section-login">
      <h2>Itinéraires</h2>
      <h1>ESCALES ©</h1>
      <div className="divider-login"></div>
      <div className="tagline-login">
        <p>PORTAIL MEMBRES</p>
      </div>
    </div>
    <div className="store-buttons-login">
      <img
        src="https://fwhkmvwumtdtakjntdol.supabase.co/storage/v1/object/public/web/googleplay.png?t=2024-11-27T03%3A34%3A46.059Z"
        alt="Get it on Google Play"
      />
      <img
        src="https://fwhkmvwumtdtakjntdol.supabase.co/storage/v1/object/public/web/apple-store.png?t=2024-11-27T03%3A35%3A01.331Z"
        alt="Download on the App Store"
      />
    </div>
  </div>
</div>
    </div>
  );
};

export default LoginPage;

