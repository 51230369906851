import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import PlaceList from '../PlaceList/PlaceList';
import './PlaceManager.css';

const PlaceManager = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [newPlace, setNewPlace] = useState({
    // Place data
    title: '',
    description: '',
    introduction: '',
    latitude: '',
    longitude: '',
    image_url: '',
    video_url: '',
    city_id: '',
    // Place details
    video_urls: [''],
    podcast_cover_image_url: [''],
    podcasts: [{
      id: 'podcast-1',
      title: '',
      description: '',
      audio_url: '',
      image_url: ''
    }],
    image_urls: [''],
    ar_view_urls: [''],
    wikis: [{
      id: 'wiki-1',
      title: '',
      description: ''
    }]
  });

  useEffect(() => {
    const checkAdminStatus = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setIsAdmin(user?.id === '3e0607aa-51f3-4bfc-b276-c9ebe9328275');
    };
    checkAdminStatus();
  }, []);

  useEffect(() => {
    if (isAdmin) {
      const fetchCities = async () => {
        setLoading(true);
        const { data, error } = await supabase.from('cities').select('*');
        if (error) {
          console.error('Error loading cities:', error);
        } else {
          setCities(data);
        }
        setLoading(false);
      };
      fetchCities();
    }
  }, [isAdmin]);

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    setSelectedCity(cityId);
    setNewPlace(prev => ({
      ...prev,
      city_id: cityId
    }));
  };

  const handleBasicChange = (e) => {
    const { name, value } = e.target;
    setNewPlace(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleArrayChange = (field, index, value) => {
    setNewPlace(prev => ({
      ...prev,
      [field]: prev[field].map((item, i) => i === index ? value : item)
    }));
  };

  const handlePodcastChange = (index, field, value) => {
    setNewPlace(prev => ({
      ...prev,
      podcasts: prev.podcasts.map((podcast, i) => 
        i === index ? { ...podcast, [field]: value } : podcast
      )
    }));
  };

  const handleWikiChange = (index, field, value) => {
    setNewPlace(prev => ({
      ...prev,
      wikis: prev.wikis.map((wiki, i) => 
        i === index ? { ...wiki, [field]: value } : wiki
      )
    }));
  };

  const addArrayItem = (field) => {
    setNewPlace(prev => ({
      ...prev,
      [field]: [...prev[field], '']
    }));
  };

  const addComplexItem = (field, template) => {
    setNewPlace(prev => ({
      ...prev,
      [field]: [...prev[field], { ...template }]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Insert into places table
      const placeData = {
        id: selectedCity,
        title: newPlace.title,
        description: newPlace.description,
        introduction: newPlace.introduction,
        latitude: parseFloat(newPlace.latitude),
        longitude: parseFloat(newPlace.longitude),
        image_url: newPlace.image_url,
        video_url: newPlace.video_url,
        city_id: selectedCity,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      const { data: placeResult, error: placeError } = await supabase
        .from('places')
        .insert([placeData])
        .select()
        .single();

      if (placeError) throw placeError;

      // Insert into place_details table
      const detailsData = {
        place_id: selectedCity,
        video_urls: newPlace.video_urls.filter(Boolean),
        podcast_cover_image_url: newPlace.podcast_cover_image_url.filter(Boolean),
        podcasts: newPlace.podcasts.filter(p => p.title || p.description),
        image_urls: newPlace.image_urls.filter(Boolean),
        ar_view_urls: newPlace.ar_view_urls.filter(Boolean),
        wikis: newPlace.wikis.filter(w => w.title || w.description)
      };

      const { error: detailsError } = await supabase
        .from('place_details')
        .insert([detailsData]);

      if (detailsError) throw detailsError;

      alert('Lieu créé avec succès !');
      setShowForm(false);
      window.location.reload(); // Refresh to show new data

    } catch (error) {
      console.error('Error creating place:', error);
      alert('Erreur lors de la création du lieu: ' + error.message);
    }
  };

  if (loading) return <div>Chargement...</div>;
  if (!isAdmin) return <div>Access Denied</div>;

  return (
    <div className="place-manager">
      <div className="place-manager-header">
        <h2>Gestion des lieux</h2>
        {selectedCity && !showForm && (
          <button 
            onClick={() => setShowForm(true)}
            className="add-place-button"
          >
            Ajouter un lieu
          </button>
        )}
      </div>

      <div className="city-selector">
        <label>Sélectionner une ville:</label>
        <select
          value={selectedCity || ''}
          onChange={handleCityChange}
          className="city-select"
        >
          <option value="">-- Sélectionner une ville --</option>
          {cities.map((city) => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
        </select>
      </div>

      {selectedCity && showForm ? (
        <form onSubmit={handleSubmit} className="place-form">
          <button 
            type="button" 
            className="back-button"
            onClick={() => setShowForm(false)}
          >
            ← Retour
          </button>

          <h3>Informations de base</h3>
          <div className="form-group">
            <label>Titre:</label>
            <input
              type="text"
              name="title"
              value={newPlace.title}
              onChange={handleBasicChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Description:</label>
            <textarea
              name="description"
              value={newPlace.description}
              onChange={handleBasicChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Introduction:</label>
            <textarea
              name="introduction"
              value={newPlace.introduction}
              onChange={handleBasicChange}
              required
            />
          </div>

          <div className="coordinates">
            <div className="form-group">
              <label>Latitude:</label>
              <input
                type="number"
                step="any"
                name="latitude"
                value={newPlace.latitude}
                onChange={handleBasicChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Longitude:</label>
              <input
                type="number"
                step="any"
                name="longitude"
                value={newPlace.longitude}
                onChange={handleBasicChange}
                required
              />
            </div>
          </div>

          {/* Media URLs */}
          {['video_urls', 'image_urls', 'ar_view_urls', 'podcast_cover_image_url'].map(field => (
            <div key={field} className="form-section">
              <h3>{field.replace(/_/g, ' ').toUpperCase()}</h3>
              {newPlace[field].map((url, index) => (
                <div key={`${field}-${index}`} className="form-group">
                  <label>{`URL ${index + 1}:`}</label>
                  <input
                    type="text"
                    value={url}
                    onChange={(e) => handleArrayChange(field, index, e.target.value)}
                  />
                </div>
              ))}
              <button
                type="button"
                onClick={() => addArrayItem(field)}
                className="add-item-button"
              >
                + Ajouter une URL
              </button>
            </div>
          ))}

          {/* Podcasts */}
          <div className="form-section">
            <h3>PODCASTS</h3>
            {newPlace.podcasts.map((podcast, index) => (
              <div key={`podcast-${index}`} className="complex-item">
                <h4>Podcast {index + 1}</h4>
                <div className="form-group">
                  <label>Titre:</label>
                  <input
                    type="text"
                    value={podcast.title}
                    onChange={(e) => handlePodcastChange(index, 'title', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Description:</label>
                  <textarea
                    value={podcast.description}
                    onChange={(e) => handlePodcastChange(index, 'description', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>URL Audio:</label>
                  <input
                    type="text"
                    value={podcast.audio_url}
                    onChange={(e) => handlePodcastChange(index, 'audio_url', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>URL Image:</label>
                  <input
                    type="text"
                    value={podcast.image_url}
                    onChange={(e) => handlePodcastChange(index, 'image_url', e.target.value)}
                  />
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addComplexItem('podcasts', {
                id: `podcast-${newPlace.podcasts.length + 1}`,
                title: '',
                description: '',
                audio_url: '',
                image_url: ''
              })}
              className="add-item-button"
            >
              + Ajouter un podcast
            </button>
          </div>

          {/* Wiki Sections */}
          <div className="form-section">
            <h3>WIKI</h3>
            {newPlace.wikis.map((wiki, index) => (
              <div key={`wiki-${index}`} className="complex-item">
                <h4>Section {index + 1}</h4>
                <div className="form-group">
                  <label>Titre:</label>
                  <input
                    type="text"
                    value={wiki.title}
                    onChange={(e) => handleWikiChange(index, 'title', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Description:</label>
                  <textarea
                    value={wiki.description}
                    onChange={(e) => handleWikiChange(index, 'description', e.target.value)}
                  />
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addComplexItem('wikis', {
                id: `wiki-${newPlace.wikis.length + 1}`,
                title: '',
                description: ''
              })}
              className="add-item-button"
            >
              + Ajouter une section wiki
            </button>
          </div>

          <div className="button-group">
            <button type="submit" className="save-button">
              Créer le lieu
            </button>
            <button 
              type="button" 
              onClick={() => setShowForm(false)} 
              className="cancel-button"
            >
              Annuler
            </button>
          </div>
        </form>
      ) : (
        selectedCity && <PlaceList cityId={selectedCity} />
      )}
    </div>
  );
};

export default PlaceManager;