import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { Plus } from 'lucide-react';
import './CityManager.css';
import AddCityForm from './AddCityForm';
import CityCard from './CityCard';

const CityManager = () => {
  const [cities, setCities] = useState([]);
  const [userCities, setUserCities] = useState([]);
  const [isAdmin, setIsAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);

  useEffect(() => {
    fetchAdminStatus();
  }, []);

  useEffect(() => {
    if (isAdmin !== null) fetchCities();
  }, [isAdmin]);

  // Check if user is admin
  const fetchAdminStatus = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setIsAdmin(user?.id === '3e0607aa-51f3-4bfc-b276-c9ebe9328275');
  };

  // Load cities based on admin status
  const fetchCities = async () => {
    setLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const query = isAdmin
        ? supabase.from('cities').select('*').order('name')
        : supabase.from('city_managers').select('cities (*)').eq('user_id', user.id);

      const { data, error } = await query;
      if (error) throw error;

      isAdmin ? setCities(data) : setUserCities(data.map(item => item.cities));
    } catch (error) {
      console.error('Error loading cities:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Chargement...</div>;

  return (
    <div>
      {isAdmin && (
        <div className="add-city-section">
          <button onClick={() => setShowAddForm(true)} className="add-city-button">
            <Plus size={16} /> Ajouter une ville
          </button>
          {showAddForm && <AddCityForm onClose={() => setShowAddForm(false)} onAddCity={fetchCities} />}
        </div>
      )}
      <div className="dashboard-grid">
        {(isAdmin ? cities : userCities).map(city => (
          <CityCard key={city.id} city={city} onUpdate={fetchCities} />
        ))}
      </div>
    </div>
  );
};

export default CityManager;
