import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import './PlaceEditor.css';

const PlaceEditor = () => {
  const { placeId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editablePlace, setEditablePlace] = useState({
    title: '',
    description: '',
    introduction: '',
    latitude: '',
    longitude: '',
    image_url: '',
    video_url: '',
    city_id: '',
    videos: [],
    podcast_covers: [],
    podcasts: [],
    images: [],
    ar_images: [],
    wiki_sections: []
  });

  const parseJsonField = (field) => {
    if (typeof field === 'string') {
      try {
        return JSON.parse(field);
      } catch (e) {
        return [];
      }
    }
    return field || [];
  };

  useEffect(() => {
    const fetchPlaceData = async () => {
      try {
        // Fetch basic place data
        const { data: placeData, error: placeError } = await supabase
          .from('places')
          .select('*')
          .eq('id', placeId)
          .single();

        if (placeError) throw placeError;

        // Fetch place details
        const { data: detailsData, error: detailsError } = await supabase
          .from('place_details')
          .select('*')
          .eq('place_id', placeId)
          .single();

        if (detailsError) throw detailsError;

        // Safely parse the JSON fields
        const parsedDetails = {
          videos: parseJsonField(detailsData.videos),
          podcast_covers: parseJsonField(detailsData.podcast_covers),
          podcasts: parseJsonField(detailsData.podcasts),
          images: parseJsonField(detailsData.images),
          ar_images: parseJsonField(detailsData.ar_images),
          wiki_sections: parseJsonField(detailsData.wiki_sections)
        };

        console.log('Parsed Details:', parsedDetails); // Debug log

        setEditablePlace({
          ...placeData,
          ...parsedDetails
        });

      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPlaceData();
  }, [placeId]);

  const handleBasicChange = (e) => {
    const { name, value } = e.target;
    setEditablePlace(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleArrayChange = (field, index, value) => {
    setEditablePlace(prev => ({
      ...prev,
      [field]: prev[field].map((item, i) => i === index ? value : item)
    }));
  };

  const handlePodcastChange = (index, field, value) => {
    setEditablePlace(prev => ({
      ...prev,
      podcasts: prev.podcasts.map((podcast, i) => 
        i === index ? { ...podcast, [field]: value } : podcast
      )
    }));
  };

  const handleWikiSectionChange = (index, field, value) => {
    setEditablePlace(prev => ({
      ...prev,
      wiki_sections: prev.wiki_sections.map((section, i) => 
        i === index ? { ...section, [field]: value } : section
      )
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      // Prepare data for places table
      const placesData = {
        title: editablePlace.title,
        description: editablePlace.description,
        introduction: editablePlace.introduction,
        latitude: editablePlace.latitude,
        longitude: editablePlace.longitude,
        image_url: editablePlace.image_url,
        video_url: editablePlace.video_url,
        city_id: editablePlace.city_id,
      };

      // Update places table
      const { error: placesError } = await supabase
        .from('places')
        .update(placesData)
        .eq('id', placeId);

      if (placesError) throw placesError;

      // Prepare data for place_details table - no need to stringify again
      const detailsData = {
        videos: editablePlace.videos,
        podcast_covers: editablePlace.podcast_covers,
        podcasts: editablePlace.podcasts,
        images: editablePlace.images,
        ar_images: editablePlace.ar_images,
        wiki_sections: editablePlace.wiki_sections,
      };

      // Update place_details table
      const { error: detailsError } = await supabase
        .from('place_details')
        .update(detailsData)
        .eq('place_id', placeId);

      if (detailsError) throw detailsError;

      alert('All place data updated successfully!');
    } catch (err) {
      console.error('Error updating place data:', err);
      alert('Error updating place: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="place-editor">
      <h2>Edit Place Details</h2>
      
      {/* Basic Information */}
      <section className="editor-section">
        <h3>Basic Information</h3>
        <div className="form-group">
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={editablePlace.title || ''}
            onChange={handleBasicChange}
          />
        </div>
        
        <div className="form-group">
          <label>Description:</label>
          <textarea
            name="description"
            value={editablePlace.description || ''}
            onChange={handleBasicChange}
          />
        </div>

        <div className="form-group">
          <label>Introduction:</label>
          <textarea
            name="introduction"
            value={editablePlace.introduction || ''}
            onChange={handleBasicChange}
          />
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Latitude:</label>
            <input
              type="number"
              name="latitude"
              value={editablePlace.latitude || ''}
              onChange={handleBasicChange}
              step="any"
            />
          </div>

          <div className="form-group">
            <label>Longitude:</label>
            <input
              type="number"
              name="longitude"
              value={editablePlace.longitude || ''}
              onChange={handleBasicChange}
              step="any"
            />
          </div>
        </div>
      </section>

      {/* Videos */}
      <section className="editor-section">
        <h3>Videos</h3>
        {editablePlace.videos.map((url, index) => (
          <div key={`video-${index}`} className="form-group">
            <label>Video URL {index + 1}:</label>
            <input
              type="text"
              value={url || ''}
              onChange={(e) => handleArrayChange('videos', index, e.target.value)}
            />
          </div>
        ))}
      </section>

      {/* Podcasts */}
      <section className="editor-section">
        <h3>Podcasts</h3>
        {editablePlace.podcasts.map((podcast, index) => (
          <div key={`podcast-${index}`} className="podcast-item">
            <h4>Podcast {index + 1}</h4>
            <div className="form-group">
              <label>Title:</label>
              <input
                type="text"
                value={podcast.title || ''}
                onChange={(e) => handlePodcastChange(index, 'title', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Description:</label>
              <textarea
                value={podcast.description || ''}
                onChange={(e) => handlePodcastChange(index, 'description', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Audio URL:</label>
              <input
                type="text"
                value={podcast.audio_url || ''}
                onChange={(e) => handlePodcastChange(index, 'audio_url', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Image URL:</label>
              <input
                type="text"
                value={podcast.image_url || ''}
                onChange={(e) => handlePodcastChange(index, 'image_url', e.target.value)}
              />
            </div>
          </div>
        ))}
      </section>

      {/* Wiki Sections */}
      <section className="editor-section">
        <h3>Wiki Sections</h3>
        {editablePlace.wiki_sections.map((section, index) => (
          <div key={`wiki-${index}`} className="wiki-section">
            <h4>Section {index + 1}</h4>
            <div className="form-group">
              <label>Title:</label>
              <input
                type="text"
                value={section.title || ''}
                onChange={(e) => handleWikiSectionChange(index, 'title', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Description:</label>
              <textarea
                value={section.description || ''}
                onChange={(e) => handleWikiSectionChange(index, 'description', e.target.value)}
              />
            </div>
          </div>
        ))}
      </section>

      <div className="button-group">
        <button onClick={handleSave} className="save-button" disabled={loading}>
          {loading ? 'Sauvegarde...' : 'Sauvegarder tout'}
        </button>
        <button onClick={() => window.location.reload()} className="cancel-button">
          Annuler
        </button>
      </div>
    </div>
  );
};

export default PlaceEditor;