import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';
import { Edit, Save, X, Trash } from 'lucide-react';

const EscaleCard = ({ escale, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localEscale, setLocalEscale] = useState({
    newName: escale.name,
  });

  const handleSave = async () => {
    try {
      const { error } = await supabase
        .from('escales')
        .update({ name: localEscale.newName })
        .eq('id', escale.id);

      if (error) throw error;
      onUpdate();
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating escale:', error);
      alert('Error updating escale');
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer cette escale ?");
    if (confirmDelete) {
      try {
        const { error } = await supabase.from('escales').delete().eq('id', escale.id);
        if (error) throw error;
        onUpdate();
      } catch (error) {
        console.error('Error deleting escale:', error);
        alert('Error deleting escale');
      }
    }
  };

  return (
    <div className="dashboard-card">
      {isEditing ? (
        <div className="edit-form">
          <input
            value={localEscale.newName}
            onChange={(e) => setLocalEscale({ ...localEscale, newName: e.target.value })}
            className="edit-input"
            placeholder="Nom de l'escale"
          />
          <div className="edit-actions">
            <button onClick={handleSave} className="save-button">
              <Save size={16} /> Sauvegarder
            </button>
            <button onClick={() => setIsEditing(false)} className="cancel-button">
              <X size={16} /> Annuler
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="card-header">
            <h3 className="card-title">{escale.name}</h3>
          </div>
          <div className="action-buttons">
            <button onClick={() => setIsEditing(true)} className="edit-button">
              <Edit size={16} /> Éditer
            </button>
            <button onClick={handleDelete} className="delete-button">
              <Trash size={16} /> Supprimer
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default EscaleCard;
