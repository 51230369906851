import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { Edit, Save, X, Plus, Trash } from 'lucide-react';
import './Escales.css';
import EscaleCard from './EscaleCard';



const AddEscaleForm = ({ onSubmit, onCancel }) => {
  const [newName, setNewName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newEscale = { id: crypto.randomUUID(), name: newName };
      const { error } = await supabase.from('escales').insert([newEscale]);

      if (error) throw error;
      onSubmit();
      setNewName('');
    } catch (error) {
      console.error('Error adding escale:', error);
      alert(`Error adding escale: ${error.message}`);
    }
  };

  return (
    <div className="dashboard-card">
      <form onSubmit={handleSubmit} className="edit-form">
        <input
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          className="edit-input"
          placeholder="Nom de l'escale"
          required
        />
        <div className="edit-actions">
          <button type="submit" className="save-button">
            <Save size={16} /> Sauvegarder
          </button>
          <button type="button" onClick={onCancel} className="cancel-button">
            <X size={16} /> Annuler
          </button>
        </div>
      </form>
    </div>
  );
};

const Escales = () => {
  const [escales, setEscales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);

  const loadEscales = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase.from('escales').select('*').order('name');
      if (error) throw error;
      setEscales(data);
    } catch (error) {
      console.error('Error loading escales:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadEscales();
  }, []);

  if (loading) return <div>Chargement...</div>;

  return (
    <div>
      <div className="add-section">
        {!showAddForm ? (
          <button onClick={() => setShowAddForm(true)} className="add-button">
            <Plus size={16} /> Ajouter une escale
          </button>
        ) : (
          <AddEscaleForm
            onSubmit={() => {
              loadEscales();
              setShowAddForm(false);
            }}
            onCancel={() => setShowAddForm(false)}
          />
        )}
      </div>
      <div className="dashboard-grid">
        {escales.map((escale) => (
          <EscaleCard key={escale.id} escale={escale} onUpdate={loadEscales} />
        ))}
      </div>
    </div>
  );
};

export default Escales;
