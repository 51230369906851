import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../supabaseClient';

const PlaceList = ({ cityId }) => {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newPlace, setNewPlace] = useState({
    title: '',
    description: '',
    introduction: '',
    city_id: cityId
  });
  const [isAddingPlace, setIsAddingPlace] = useState(false);

  useEffect(() => {
    fetchPlaces();
  }, [cityId]);

  const fetchPlaces = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from('places')
      .select('*')
      .eq('city_id', cityId);

    if (error) {
      console.error('Error fetching places:', error);
    } else {
      setPlaces(data);
    }
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPlace(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCreatePlace = async (e) => {
    e.preventDefault();
    try {
      // Insert into places table
      const { data: placeData, error: placeError } = await supabase
        .from('places')
        .insert([{ ...newPlace, city_id: cityId }])
        .select()
        .single();

      if (placeError) throw placeError;

      // Initialize place_details with empty arrays
      const { error: detailsError } = await supabase
        .from('place_details')
        .insert([{
          place_id: placeData.id,
          videos: [],
          podcast_covers: [],
          podcasts: [],
          images: [],
          ar_images: [],
          wiki_sections: []
        }]);

      if (detailsError) throw detailsError;

      // Reset form and refresh list
      setNewPlace({
        title: '',
        description: '',
        introduction: '',
        city_id: cityId
      });
      setIsAddingPlace(false);
      fetchPlaces();
      
    } catch (error) {
      console.error('Error creating place:', error);
      alert('Error creating place: ' + error.message);
    }
  };

  if (loading) return <div>Chargement...</div>;

  return (
    <div className="place-list">
      <div className="place-list-header">
        <h3>Lieux dans la ville sélectionnée</h3>
      
      </div>

      {isAddingPlace && (
        <form onSubmit={handleCreatePlace} className="add-place-form">
          <div className="form-group">
            <label>
              Titre:
              <input
                type="text"
                name="title"
                value={newPlace.title}
                onChange={handleInputChange}
                required
                className="form-control"
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Description:
              <textarea
                name="description"
                value={newPlace.description}
                onChange={handleInputChange}
                className="form-control"
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Introduction:
              <textarea
                name="introduction"
                value={newPlace.introduction}
                onChange={handleInputChange}
                className="form-control"
              />
            </label>
          </div>

          <button type="submit" className="submit-button">
            Créer le lieu
          </button>
        </form>
      )}

      <ul className="places-list">
        {places.map((place) => (
          <li key={place.id} className="place-item">
            <div className="place-info">
              <h4>{place.title}</h4>
              <p>{place.description}</p>
            </div>
            <div className="place-actions">
              <Link 
                to={`/edit-place/${place.id}`}
                className="edit-button"
              >
                Modifier
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlaceList;