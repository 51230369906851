import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';
import { Save, X } from 'lucide-react';

const AddCityForm = ({ onClose, onAddCity }) => {
  const [newCity, setNewCity] = useState({
    name: '',
    description: '',
    image_url: '',
    highlight_uuid: '',
    escale_id: '1cbbb714-cb19-455b-a36c-41a673e0b516' // default
  });

  const handleAddCity = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.from('cities').insert([{
        ...newCity,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }]);
      if (error) throw error;

      onAddCity();
      onClose();
      setNewCity({
        name: '',
        description: '',
        image_url: '',
        highlight_uuid: '',
        escale_id: '1cbbb714-cb19-455b-a36c-41a673e0b516'
      });
    } catch (error) {
      console.error('Error adding city:', error);
      alert('Error adding city');
    }
  };

  return (
    <div className="dashboard-card">
      <form onSubmit={handleAddCity} className="edit-form">
        <input
          value={newCity.name}
          onChange={(e) => setNewCity({ ...newCity, name: e.target.value })}
          className="edit-input"
          placeholder="Nom de la ville"
          maxLength={25} // Limite de 25 caractères
          required
        />
        <textarea
          value={newCity.description}
          onChange={(e) => setNewCity({ ...newCity, description: e.target.value })}
          className="edit-textarea"
          placeholder="Description"
          maxLength={75} // Limite de 75 caractères
          required
        />
        <input
          value={newCity.image_url}
          onChange={(e) => setNewCity({ ...newCity, image_url: e.target.value })}
          className="edit-input"
          placeholder="URL de l'image"
          required
        />
        <input
          value={newCity.highlight_uuid}
          onChange={(e) => setNewCity({ ...newCity, highlight_uuid: e.target.value })}
          className="edit-input"
          placeholder="UUID du highlight"
          required
        />
        <div className="edit-actions">
          <button type="submit" className="save-button">
            <Save size={16} /> Sauvegarder
          </button>
          <button type="button" onClick={onClose} className="cancel-button">
            <X size={16} /> Annuler
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCityForm;
